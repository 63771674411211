<template>
    <modalContent name="email-note" ref="email-note" title="Task" width="50%" close @closed="onClose">
        <template #content>
            <div class="note-text-area">
                <div class="flex items-center mb-4">
                    <date-picker @change="handleClearTime()" v-model="emailActions.date" :disabled-date="DisableDate" :disabled="!emailActions.task" class="ml-3 w-20" placeholder="Date" :class="{ 'date-error': hasEmailErrors.date }" />
                    <date-picker v-model="emailActions.time" :disabled-time="disabledHours" :disabled="!emailActions.task" popup-class="time-picker" class="ml-3" type="time" placeholder="Time" :show-minute="false" :show-second="false" format="HH" :class="{ 'time-error': hasEmailErrors.time }" />
                    <dv-dropdown
                        class="ml-3"
                        width="180px"
                        height="150px"
                        placeholder="Assignee"
                        :colors="{
                            bg: '#fff',
                            text: '#282828',
                            border: hasEmailErrors.task_assignee_id ? '#F44336' : '#d3d3d3',
                            svg: '#0D69D5',
                        }"
                        :options="groupedAssigneeOptions"
                        :config="{ label: 'user_name', 'track-by': 'user_name', 'group-values': 'assignee', 'group-label': 'group_name', 'allow-empty': false }"
                        @input="(value) => onAssigneChange(value)"
                        :disabled="!emailActions.task"
                        v-model="assignee"
                        v-if="showAssignee"
                        :allowEmpty="false"
                    />
                </div>
                <textarea class="text-area-control" :class="{ 'border border-solid border-red-500': hasEmailErrors.notes }" rows="5" placeholder="Type your text here" v-model="emailActions.notes" />
            </div>
        </template>
        <template #footer>
            <footer class="flex items-center w-full justify-between">
                <button class="h-9 w-32 bg-blue-600 cursor-pointer hover:bg-blue-700 border-none rounded-xl text-base text-white-text flex justify-center items-center font-semibold ml-auto" @click="submitModal">Save</button>
            </footer>
        </template>
    </modalContent>
</template>

<script>
const modalContent = () => import("@shared/modal-content");
const DatePicker = () => import("vue2-datepicker");
const DvDropdown = () => import("@/components/dropdown-base");
import "vue2-datepicker/index.css";
import { cloneDeep } from "lodash";

export default {
    name: "Email-Note",
    props: {
        assigneeOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            emailActions: {},
            assignee: null,
            // task: false,
            // 	date: null,
            // 	time: null,
            // 	task_assignee_id: null,
            // 	notes: "",
            // 	id: null,
            // 	task_assignee_type: null,
            // 	isClicked: false
        };
    },
    components: {
        modalContent,
        DatePicker,
        DvDropdown,
    },
    methods: {
        showModal(event = {}) {
            this.assignee = null;
            this.emailActions = cloneDeep(event);
            if (this.emailActions?.task_assignee_id) {
                this.assignee = this.assigneeOptions.find((assignee) => assignee.id === this.emailActions.task_assignee_id);
            }
            this.$refs["email-note"].showModal();
        },
        submitModal() {
            this.emailActions.isClicked = true;
            const { time, date, task_assignee_id, notes, task_assignee_type } = this.emailActions;
            this.$emit("submit", this.emailActions);
            const condition = this.showAssignee ? !task_assignee_type || !task_assignee_id : false;
            if (!time || !date || !notes || condition) {
                this.$toast.error("Please fill all the mandatory fields.");
                return;
            }
            this.$refs["email-note"].hideModal();
        },
        onClose() {
            // this.notes = "";
        },
        onBeforeOpen() {
            // this.assignee = null;
            // this.emailActions = cloneDeep(this.actions);
            // console.log("this.actions :>> ", this.actions);
            // if (this.emailActions?.task_assignee_id) {
            //     this.assignee = this.assigneeOptions.find((assignee) => assignee.id === this.emailActions.task_assignee_id);
            // }
        },
        onAssigneChange(value) {
            this.emailActions.task_assignee_id = value?.id ?? null;
            this.emailActions.task_assignee_type = value?.user_type ?? null;
        },
        DisableDate(date){
            return date < new Date(new Date().setHours(0, 0, 0, 0))
        },
        disabledHours(date){
            let dueDate = new Date(new Date(this.emailActions.date).setHours(0, 0, 0, 0)).getTime()
            let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
            if(dueDate === today){
                return date.getHours() <= new Date().getHours();
            }
        },
        handleClearTime(){
            this.emailActions.time = null
        }
    },
    computed: {
        hasEmailErrors() {
            const { task, time, date, task_assignee_id, notes, isClicked } = this.emailActions;
            return {
                date: isClicked && task && !date,
                time: isClicked && task && !time,
                task_assignee_id: this.showAssignee ? isClicked && task && !task_assignee_id : false,
                notes: isClicked && task && !notes,
                content: isClicked && !this.computedContent,
            };
        },
        groupedAssigneeOptions() {
            let options = cloneDeep(this.assigneeOptions);
            let result = options.reduce(function (r, a) {
                r[a.user_type] = r[a.user_type] || [];
                r[a.user_type].push(a);
                return r;
            }, Object.create(null));
            result = Object.entries(result).map(([k, v]) => ({ group_name: k, assignee: v }));
            return result;
        },
        showAssignee() {
            const { isEdit, task_assignee_id, task_assignee_type } = this.emailActions;
            return isEdit ? task_assignee_id && task_assignee_type : true;
        },
    },
};
</script>

<style lang="scss" scoped>
.date-error ::v-deep .mx-input {
    border: 1px solid #f44336 !important;
}
.time-error ::v-deep .mx-input {
    border: 1px solid #f44336 !important;
}
::v-deep {
    .multiselect__content-wrapper {
        max-height: 180px !important;
    }
}
</style>
