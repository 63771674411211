import { render, staticRenderFns } from "./email-note.vue?vue&type=template&id=38adc02f&scoped=true"
import script from "./email-note.vue?vue&type=script&lang=js"
export * from "./email-note.vue?vue&type=script&lang=js"
import style0 from "./email-note.vue?vue&type=style&index=0&id=38adc02f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38adc02f",
  null
  
)

export default component.exports